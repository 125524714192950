<template>
  <AdminOverview>
    <h1 class="dashboard-title">Hi there!</h1>
    <div class="dashboard-cards">
      <router-link :to="card.link" class="card" key="card" v-for="card in cards">
        <div class="card-icon">
          <i :class="['fal', card.icon, 'fa-2x']"></i>
        </div>
        <div class="card-title">
          {{ card.title }}
        </div>
      </router-link>
    </div>
  </AdminOverview>
</template>

<script>
import AdminOverview from '@/components/admin/AdminOverview'

export default {
  name: 'Dashboard',
  components: {
    AdminOverview,
  },
  data: () => ({
    cards: [
      {
        title: 'Events',
        icon: 'fa-calendar-alt',
        link: '/admin/events',
      },
      {
        title: 'Games',
        icon: 'fa-gamepad-alt',
        link: '/admin/games',
      },
    ]
  }),
}
</script>

<style lang="scss" scoped>
a {
  color: #fff;
  text-decoration: none;
  opacity: 1;
  transition: opacity 150ms ease;

  &:hover {
    opacity: 0.6;
  }
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;

  .card {
    background-color: #445;
    padding: 3.75rem 5rem;
    border-radius: 1rem;

    .card-title {
      margin-top: 1rem;
      font-size: 2em;
      font-weight: 500;
    }
  }
}
</style>